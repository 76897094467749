.arrow {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #ffe6b9;
  border-right: 5px solid #ffe6b9;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

#xsImage {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  bottom: 0px;

  @media only screen and (max-height: 700px) {
    bottom: 50px;
  }

  @media only screen and (max-height: 650px) {
    bottom: 100px;
  }

  @media only screen and (max-height: 600px) {
    bottom: 150px;
  }
}

#xlImage {
  height: 100vh;
}

#nav-item :hover {
  background: lighten(#f4c346, 3%);
  border: 1px solid darken(#f4c346, 4%);
  box-shadow: 0px 2px 0 darken(#f4c346, 5%), 2px 4px 6px darken(#f4c346, 2%);
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 150ms linear;
}
