@media (max-width: 600px) {
  #timePickerDialog
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    min-width: 230px;
  }
}

@media (min-width: 600px) {
  #timePickerDialog
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    min-width: 400px;
  }
}
